.select {
  position: relative;
  border-radius: 4px;
  color: #000000;
  min-width: 260px;
  margin: 16px 0; }
  .select__intup {
    background: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 20px;
    position: relative;
    width: 100%;
    box-sizing: border-box; }
  .select__wrap {
    font-size: 14px;
    color: #000;
    padding: 10px;
    position: absolute;
    border: solid 1px  #dadbdc;
    width: 100%;
    top: 45px;
    z-index: 15;
    background: #fff;
    overflow-y: auto;
    transition: 0.5s;
    max-height: 0;
    opacity: 0;
    visibility: hidden; }
    .select__wrap-active {
      max-height: 150px;
      transition: 0.5s;
      opacity: 1;
      visibility: visible; }
    .select__wrap_item {
      padding: 5px 10px;
      font-weight: 500; }
      .select__wrap_item:first-child {
        padding-top: 0px; }
      .select__wrap_item:last-child {
        padding-bottom: 0px; }
  .select .field_text {
    color: inherit;
    width: 90%; }

.field__icon, .field .field__icon_click {
  height: 100%;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
