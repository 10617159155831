.form_row {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .form_row-three {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 30px; }
  .form_row-checkbox {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }

.form_container__header {
  flex-wrap: initial !important; }

.form_container__step {
  background-color: #ffc80d;
  height: 65px;
  width: 65px;
  min-width: 65px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #2f2d2d;
  display: flex;
  align-items: center;
  justify-content: center; }

.form_container__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #2f2d2d;
  margin-left: 30px; }

.form_container-big-margin {
  margin-left: 95px; }

.form_container-midddle-margin {
  margin-left: 45px; }

.form_btn {
  margin-top: 30px; }
  .form_btn .btn {
    margin: inherit; }

@media screen and (max-width: 1200px) {
  .form_container__step {
    height: 55px;
    width: 55px;
    min-width: 55px; }
  .form_container__title {
    font-size: 20px; } }

@media screen and (max-width: 768px) {
  .form_container-big-margin {
    margin: 30px 0 20px 15px; }
  .form_container-midddle-margin {
    margin: 30px 0 20px 10px; } }

@media screen and (max-width: 420px) {
  .form_container__step {
    height: 45px;
    width: 45px;
    font-size: 20px;
    min-width: 45px; }
  .form_container__title {
    margin-left: 15px;
    font-size: 19px; } }
