.conatainer {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto; }
  .conatainer_box {
    margin: 0 7.5%; }
    .conatainer_box_left {
      margin: 0 0 0 7.5%; }
    .conatainer_box_right {
      margin: 0 7.5% 0 0; }

.overflow {
  overflow: hidden; }

main {
  margin-top: 105px; }

@media screen and (max-width: 768px) {
  main {
    margin-top: 65px; } }

@media screen and (max-width: 1500px) {
  .conatainer {
    width: auto; } }

.bg-1 {
  background-color: #f9fafa; }

.bg-2 {
  background-color: #2e408a; }

.title-1 {
  font-size: 3.5rem;
  line-height: 4.9375rem; }

.title-2 {
  font-size: 2.625rem;
  line-height: 3.6875rem; }

.title-3 {
  font-size: 2rem;
  line-height: 2.8125rem; }

.title-4 {
  font-size: 1.5rem;
  line-height: 2.25rem; }

.title-5 {
  font-size: 1.25rem;
  line-height: 1.875rem; }

.title-6 {
  font-size: 1.125rem;
  line-height: 1.6875rem; }

.body-1 {
  font-size: 1rem;
  line-height: 1.5rem; }

.body-2 {
  font-size: 0.875rem;
  line-height: 1.3125rem; }

.caption-1 {
  font-size: 0.75rem;
  line-height: 1.125rem; }

.caption-2 {
  font-size: 0.625rem;
  line-height: 0.9375rem; }

.shadown-0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }

.shadown-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24); }

.shadown-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.24); }

.shadown-3 {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.24); }

.shadown-4 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.24); }

.color-primary-1 {
  color: #262729; }

.color-primary-2 {
  color: #2e408a; }

.color-error {
  color: #eb3b13; }

.color-blue-400 {
  color: #5866a1; }

.color-grey-100 {
  color: #f9fafa; }

.color-grey-200 {
  color: #f4f4f5; }

.color-grey-300 {
  color: #ebebec; }

.color-grey-400 {
  color: #dadbdc; }

.color-grey-500 {
  color: #b4b6ba; }

.color-grey-600 {
  color: #8f9297; }

.color-grey-700 {
  color: #6b6e74; }

.color-grey-800 {
  color: #494a4e; }

.color-grey-900 {
  color: #36373a; }

.gardient-1 {
  background-image: linear-gradient(to bottom, #f3404c, #2e408a 100%); }

.box-max-660 {
  max-width: 660px; }

.box-max-760 {
  max-width: 760px; }

.box-mr-center {
  margin: 0 auto; }
