.footer {
  display: grid;
  grid-template-columns: 240px 120px minmax(200px, 370px);
  grid-gap: 34px; }
  .footer_wrap {
    padding: 29px 39px; }
  .footer_coll .group {
    display: flex;
    flex-direction: column;
    align-items: baseline; }
  .footer .title {
    color: #fff; }
  .footer_link {
    padding: 8px 0;
    color: #ffffff; }
  .footer_social {
    padding: 16px 0;
    display: flex;
    align-items: center; }
    .footer_social a {
      margin-right: 20px; }
  .footer_form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    width: 100%; }

@media screen and (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr 120px; }
    .footer_coll-3 {
      grid-column-start: 1;
      grid-column-end: 3; } }

@media screen and (max-width: 470px) {
  .footer {
    grid-gap: 10px;
    grid-template-columns: 1fr; }
    .footer_coll-3 {
      grid-column-start: 1;
      grid-column-end: 2; }
    .footer_social__wrap {
      margin-top: 15px; } }
