.btn {
  text-decoration: none;
  cursor: pointer; }
  .btn-services {
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 15px;
    border-radius: 5px;
    transition: .3s; }
    .btn-services:hover {
      border-color: #556f99;
      transition: .3s;
      color: #556f99; }
  .btn-standart {
    outline: none;
    border-radius: 4px;
    background-color: #2e408a;
    padding: 9px 16px;
    color: #fff;
    display: inline-block;
    transition: 0.3s;
    border: 0px solid #d5d9e8;
    position: relative;
    z-index: 1; }
    .btn-standart::after {
      z-index: -1;
      border: 4px solid transparent;
      transition: 0.3s;
      position: absolute;
      border-radius: 4px;
      transition: 0.3s;
      content: "";
      top: -4px;
      left: -4px;
      width: 100%;
      height: 100%; }
    .btn-standart:hover {
      transition: 0.3s;
      background-color: #5866a1; }
    .btn-standart:focus::after {
      border: 4px solid #d5d9e8;
      transition: 0.3s; }
    .btn-standart-disable {
      outline: none;
      background-color: #abb3d0; }
      .btn-standart-disable:hover {
        transition: 0.3s;
        background-color: #abb3d0; }
      .btn-standart-disable:focus::after {
        border: none; }
  .btn-link {
    color: #5866a1;
    transition: 0.3s; }
    .btn-link:hover {
      transition: 0.3s;
      color: #828cb9; }
    .btn-link-grey {
      color: #494a4e;
      transition: 0.3s; }
      .btn-link-grey:hover {
        transition: 0.3s;
        color: #6d86ae; }
    .btn-link-white {
      color: #fff;
      transition: 0.3s; }
      .btn-link-white:hover {
        transition: 0.3s;
        color: #abb3d0; }
  .btn-social {
    transition: 0.3s; }
    .btn-social:hover svg path {
      transition: 0.3s;
      fill: #abb3d0; }
  .btn-foter_form {
    outline: none;
    border-radius: 4px;
    background-color: #5866a1;
    padding: 9px 16px;
    color: #fff;
    display: inline-block;
    transition: 0.3s;
    border: 2px solid transparent;
    position: relative;
    z-index: 1; }
    .btn-foter_form::after {
      z-index: -1;
      border: 4px solid transparent;
      transition: 0.3s;
      position: absolute;
      border-radius: 4px;
      transition: 0.3s;
      content: "";
      top: -4px;
      left: -4px;
      width: 100%;
      height: 100%; }
    .btn-foter_form:hover {
      transition: 0.3s;
      background-color: transparent;
      border: 2px solid #d5d9e8; }
    .btn-foter_form:focus::after {
      border: 4px solid #d5d9e8;
      transition: 0.3s; }
