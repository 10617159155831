header {
  position: fixed;
  z-index: 100;
  background: #fff;
  width: 100%;
  top: 0; }

.nav__main__footer {
  display: none; }

.lang {
  position: relative; }
  .lang-active .lang_main {
    transition: 0.3s;
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  .lang_header {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative; }
  .lang_main {
    transition: 0.3s;
    transform: translateY(-5px);
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 22px;
    background: #f9fafa;
    padding: 0px 30px 5px 5px;
    margin: 0 -5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .lang_icon {
    margin-left: 10px; }

.header__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px; }
  .header__info_conatact {
    display: flex;
    align-items: center; }
    .header__info_conatact .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      width: 16px; }
    .header__info_conatact .link {
      font-weight: 600; }
  .header__info_service {
    display: flex;
    align-items: center; }
    .header__info_service .lang {
      display: flex;
      align-items: center;
      margin: 0 20px; }
      .header__info_service .lang-icon {
        display: flex;
        align-items: center;
        margin-left: 10px; }
      .header__info_service .lang-text {
        font-weight: 600; }

.header_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px; }
  .header_nav .logo {
    height: 51px; }
    .header_nav .logo img {
      height: 100%; }
  .header_nav .header__info_conatact {
    display: none; }
  .header_nav .header_btn {
    display: none; }
  .header_nav .nav__header {
    display: none; }
  .header_nav nav {
    display: flex;
    align-items: center; }
    .header_nav nav a {
      margin-left: 32px; }

.user_icon_login {
  display: none; }
  .user_icon_login svg {
    display: block;
    fill: #494a4e;
    margin: 0 10px 0 0;
    width: 20px; }

header {
  border-bottom: 1px solid #dadbdc; }

@media screen and (max-width: 1460px) {
  .header__info, .header_nav {
    margin: 0 16px; } }

@media screen and (max-width: 768px) {
  .user_icon_login {
    display: flex;
    align-items: center; }
  .header_nav .header__info_conatact {
    display: flex; }
    .header_nav .header__info_conatact .link {
      font-size: 16px; }
  .header_nav .header_btn {
    display: block; }
  .header_nav .nav__header {
    display: grid;
    padding: 15px; }
  .header__info {
    display: none; }
  .header_btn .hamburger {
    display: flex;
    align-items: center; }
  .header_btn .hamburger_inner,
  .header_btn .hamburger_inner::before,
  .header_btn .hamburger_inner::after {
    width: 20px; }
  .nav {
    position: absolute;
    top: 120px;
    background: #f9fafa;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    left: 110%;
    top: 0;
    height: 100vh;
    transition: 0.3s; }
    .nav-active {
      transition: 0.3s;
      left: 0; }
    .nav__header {
      display: grid;
      grid-template-columns: 173px 1fr auto;
      grid-gap: 20px;
      align-items: center; }
      .nav__header .lang {
        display: flex;
        align-items: center; }
      .nav__header .header_btn {
        display: flex;
        align-items: center; }
    .nav__main nav {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px 0px; }
      .nav__main nav a {
        padding: 23px 25px;
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        margin: 0 0 2px 0;
        font-size: 18px; }
  .nav__main__footer {
    width: 100%;
    background: #fff;
    position: relative;
    display: block; }
    .nav__main__footer_btn {
      text-align: center;
      display: block;
      width: 90% !important;
      margin: 12px auto !important;
      padding: 11px !important; } }

@media screen and (max-width: 480px) {
  .header_nav .logo {
    height: 35px; } }

@media screen and (max-width: 355px) {
  .icon_phone {
    display: none !important; } }
