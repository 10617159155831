.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 40px; }
  .hero-text {
    font-weight: 600;
    margin-bottom: 40px;
    margin-left: 9%;
    text-align: justify; }
  .hero_info {
    padding: 24px 0 44px 0; }
    .hero_info .title-6 {
      margin: 24px 0;
      text-align: justify; }
  .hero_img {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden; }
    .hero_img .img_1 {
      position: absolute;
      width: 70%;
      height: auto;
      top: 0;
      right: 0; }
    .hero_img .img_2 {
      position: absolute;
      width: 60%;
      top: 50%;
      left: 0;
      transform: translate(0px, -50%); }

.hero_info p {
  font-size: 1.125rem !important;
  line-height: 1.6875rem; }

.hero_info .title-6 {
  text-align: justify; }

@media screen and (max-width: 1320px) {
  .hero_info {
    padding: 24px 0 44px 0; }
    .hero_info .title-1 {
      font-size: 2.7rem;
      line-height: 1.3; } }

@media screen and (max-width: 992px) {
  .hero_img .img_1 {
    right: -40%;
    width: 80%; }
  .hero_img .img_2 {
    width: 70%; } }

@media screen and (max-width: 768px) {
  .hero {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    margin: 0 7.5%; }
    .hero_info {
      padding: 25px 0 32px 0; }
      .hero_info .title-1 {
        font-size: 2.2rem;
        line-height: 1.3; }
    .hero_img .img_1 {
      position: relative;
      top: 0;
      right: 0;
      width: 100%; }
    .hero_img .img_2 {
      display: none; }
    .hero-text {
      margin-left: 0%;
      margin-top: 32px; } }

.tab {
  padding: 40px 0;
  padding: 40px 0;
  position: relative; }
  .tab-active {
    border-bottom: 2px solid #2e408a; }
  .tab .title-3 {
    margin-left: 9%;
    z-index: 10;
    position: relative; }
  .tab-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #f9fafa;
    top: 0;
    z-index: 0; }
  .tab-angle {
    height: 40px;
    width: 40px;
    position: absolute;
    border-bottom: 2px solid #2e408a;
    border-left: 2px solid #2e408a;
    transform: translate(-50%, 0) rotate(-45deg);
    bottom: 10px;
    left: 220px;
    z-index: 0;
    background: #f9fafa;
    transition: 0.3s; }
    .tab-angle-active {
      transition: 0.3s;
      bottom: -15px; }
  .tab_header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 45px;
    position: relative;
    z-index: 2;
    grid-gap: 10px; }
    .tab_header__item {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .tab_header__item .namber {
        border: 1px solid transparent;
        font-size: 36px;
        width: 116px;
        height: 116px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        background-image: linear-gradient(#f9fafa, #f9fafa), linear-gradient(to bottom, #f3404c, #2e408a 100%);
        border-image-slice: 2;
        border-radius: 50%;
        background-clip: content-box, border-box; }
        .tab_header__item .namber_text {
          background-image: linear-gradient(to bottom, #f3404c, #2e408a 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 36px; }
      .tab_header__item .title-5 {
        font-weight: 600;
        margin: 16px 0 8px; }
  .tab_main {
    overflow: hidden;
    position: relative;
    min-height: 0px;
    transition: 0.3s;
    transition: 0.3s; }
    .tab_main_item {
      position: absolute;
      transform: translateX(30%);
      opacity: 0;
      transition: 0.3s;
      display: grid;
      grid-template-columns: 1.3fr 0.7fr;
      grid-gap: 20px;
      align-items: center;
      padding: 40px 0; }
      .tab_main_item-active {
        transform: translateX(0%);
        opacity: 1;
        transition: all 300ms ease 250ms; }
      .tab_main_item .info {
        text-align: justify; }
        .tab_main_item .info .text {
          margin: 16px 0 0; }
        .tab_main_item .info .btn {
          margin-top: 22px; }

@media screen and (max-width: 992px) {
  .tab_header__item .title-5 {
    font-size: 1.15rem;
    line-height: 1.3; }
  .tab_main_item {
    grid-template-columns: 1fr 0.7fr; } }

@media screen and (max-width: 768px) {
  .tab_header__item .title-5 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.3;
    min-height: 52px;
    display: flex;
    align-items: center; }
  .tab_main__wrap {
    margin-right: 7.5%; }
  .tab_main_item {
    grid-template-columns: 1fr;
    margin: 0; }
    .tab_main_item .img {
      display: none; } }

@media screen and (max-width: 680px) {
  .tab_header {
    grid-template-columns: 1fr;
    margin: 20px 7.5%; }
    .tab_header__item {
      display: grid;
      grid-template-columns: 50px 1fr;
      grid-template-rows: 20px 20px;
      grid-gap: 15px;
      grid-row-gap: 10px;
      margin-bottom: 25px; }
      .tab_header__item .namber {
        grid-row-start: 1;
        grid-row-end: 3;
        width: 50px;
        height: 50px; }
      .tab_header__item .title-5 {
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.3;
        min-height: 52px;
        display: flex;
        align-items: center; } }

@media screen and (max-width: 400px) {
  .tab_header__item .title-5 {
    font-size: 1.1rem; } }

@media screen and (max-width: 360px) {
  .tab_header__item .title-5 {
    font-size: .95rem; } }

.tender {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px; }
  .tender_box {
    max-width: 400px;
    margin: 0 auto; }
  .tender_price {
    margin: 11px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .tender_price-date {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #6b6e74; }
    .tender_price-value {
      font-size: 16px;
      font-weight: bold;
      color: #262729; }
    .tender_price__wrap {
      transition: .3s; }
    .tender_price_box {
      max-height: 0;
      opacity: 0;
      transition: opacity .3s ease .2s, max-height .5s ease; }
      .tender_price_box-active {
        max-height: 500px;
        opacity: 1;
        transition: opacity .3s ease .125s, max-height .5s ease; }
  .tender_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px; }
    .tender_button .btn-tender {
      visibility: visible;
      display: block; }
    .tender_button .btn-tender-link {
      visibility: hidden;
      display: none; }
    .tender_button-active .btn-tender {
      visibility: hidden;
      display: none; }
    .tender_button-active .btn-tender-link {
      visibility: visible;
      display: block; }
  .tender__foter_asterisk {
    color: #262729;
    font-size: 10px;
    line-height: 1; }
  .tender__foter_text {
    font-size: 8px;
    text-align: justify;
    color: #262729;
    line-height: 1.3; }

.tender_title_heade {
  display: flex; }

.tender_asterisk {
  color: #262729;
  font-size: 12px;
  line-height: 1; }

.select2-container .select2-selection--single {
  height: auto; }
  .select2-container .select2-selection--single .select2-selection__placeholder {
    font-size: 16px;
    line-height: 1.5;
    color: #6b6e74; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 8px 16px;
    min-height: 28px; }

.select2-search__field {
  outline: none; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none !important;
  height: 20px !important;
  width: 20px !important;
  margin: auto !important;
  top: auto !important;
  left: auto !important; }

@media screen and (max-width: 992px) {
  .tender {
    grid-template-columns: 0.8fr 300px; }
    .tender .title-3 {
      font-size: 1.7rem; } }

@media screen and (max-width: 768px) {
  .tender {
    grid-template-columns: 1fr; } }
