.brief_st {
  margin-bottom: 70px; }

.form_title {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.46;
  color: #302d2d; }

@media screen and (max-width: 768px) {
  .feedback_smal {
    margin-bottom: 40px; }
    .feedback_smal .container-coll-3_bottom {
      grid-gap: 0; } }

@media screen and (max-width: 748px) {
  .form_col div:first-child {
    margin-top: 0; } }
