input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px #2e408a inset;
  -webkit-text-fill-color: #fff; }

.input {
  width: 100%;
  position: relative; }
  .input_box {
    width: 100%;
    position: relative; }
  .input_label {
    position: absolute;
    top: 12px;
    left: 0;
    transition: .3s;
    font-size: 16px;
    line-height: 1; }
  .input-field {
    width: 100%;
    position: relative;
    margin: 15px 0;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: transparent;
    padding: 8px 16px;
    color: #fff;
    outline: none;
    box-sizing: border-box; }
    .input-field::placeholder {
      color: #e3e3e3; }
  .input-black .input-field {
    color: #242321;
    border-bottom: 1px solid #242321; }
    .input-black .input-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321; }
    .input-black .input-field:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321; }
  .input-white .input-field {
    color: #242321; }
    .input-white .input-field::placeholder {
      color: #e3e3e3; }
    .input-white .input-field:-webkit-autofill, .input-white .input-field:-webkit-autofill:hover, .input-white .input-field:-webkit-autofill:focus, .input-white .input-field:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      -webkit-text-fill-color: #6b6e74; }

.in-focus .input_label {
  top: -10px;
  transition: .3s;
  font-size: 14px; }

.is-error.in-focus .input_label {
  color: #ff4b4b; }

.is-error input {
  border-color: #ff4b4b; }

.is-error .input_label {
  color: #ff4b4b; }

.is-error label.error {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b;
  position: absolute;
  left: 0;
  bottom: -4px; }

label.error {
  display: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b;
  bottom: 0; }
