.textarea {
  width: 100%;
  height: calc(100% - 60px);
  position: relative; }
  .textarea_box {
    position: relative;
    height: 100%; }
  .textarea_label {
    position: absolute;
    top: 12px;
    left: 0;
    transition: .3s;
    font-size: 16px;
    line-height: 1; }
  .textarea-field {
    width: 100%;
    position: relative;
    margin: 15px 0;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: transparent;
    padding: 8px 16px;
    color: #fff;
    outline: none;
    box-sizing: border-box;
    resize: none; }
    .textarea-field::placeholder {
      color: #e3e3e3; }
  .textarea-black .textarea_label {
    color: #242321; }
  .textarea-black .textarea-field {
    color: #242321;
    border-bottom: 1px solid #242321; }
  .textarea-white .textarea_label {
    color: #fff; }
  .textarea-white .textarea-field {
    color: #242321; }
    .textarea-white .textarea-field::placeholder {
      color: #e3e3e3; }
    .textarea-white .textarea-field:-webkit-autofill, .textarea-white .textarea-field:-webkit-autofill:hover, .textarea-white .textarea-field:-webkit-autofill:focus, .textarea-white .textarea-field:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      -webkit-text-fill-color: #6b6e74; }

.in-focus .textarea_label {
  top: -10px;
  transition: .3s;
  font-size: 14px; }

.is-error.in-focus .textarea_label {
  color: #ff4b4b; }

.is-error .textarea_label {
  color: #ff4b4b; }

.is-error label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b;
  position: absolute;
  left: 0;
  bottom: -4px; }

label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b;
  position: absolute;
  bottom: 0; }
